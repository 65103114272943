<template>
  <div class="line">
    <div class="row">
      <div class="form-step">
        <label for="node-6" class="label-form">
          {{ labels.event_type }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.event_type_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.event_type_id.length === 0),
          }"
          id="node-6"
          v-model="selected_event_type"
          name="event_type_id"
          required
        >
          <option
            v-for="event_type in event_types"
            :value="event_type.id"
            v-bind:key="event_type.id"
          >
            {{ event_type.name }}
          </option>
        </select>
      </div>
      <div class="promo-error" v-if="!(this.errors.event_type_id.length === 0)">
        {{ errors.event_type_id ? errors.event_type_id[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-7" class="label-form">
          {{ labels.planner_type }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.planner_type_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.type_id.length === 0),
          }"
          id="node-7"
          v-model="selected_planner_type"
          name="type_id"
          required
        >
          <option
            v-for="planner_type in planner_types"
            :value="planner_type.id"
            v-bind:key="planner_type.id"
          >
            {{ planner_type.name }}
          </option>
        </select>
      </div>
      <div class="promo-error" v-if="!(this.errors.type_id.length === 0)">
        {{ errors.type_id ? errors.type_id[0] : "" }}
      </div>
    </div>

    <div class="row">
      <div class="form-step">
        <label for="node-19" class="label-form">
          {{ labels.sponsor_country }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.oferta_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.country_id.length === 0),
          }"
          id="node-19"
          required
          v-model="selected_country_id"
          name="country_id"
          class="select _2 w-select"
        >
          <option
            v-for="country in countries"
            :value="country.id"
            v-bind:key="country.id"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
      <div class="promo-error" v-if="!(this.errors.country_id.length === 0)">
        {{ errors.country_id ? errors.country_id[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-27" class="label-form">
          {{ labels.timezone }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.timezone_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.time_zone_code.length === 0),
          }"
          id="node-27"
          required
          v-model="selected_time_zone_code"
          name="time_zone_code"
          class="select _2 w-select"
        >
          <option
            v-for="time_zone in time_zones"
            :value="time_zone.code"
            v-bind:key="time_zone.code"
          >
            {{ time_zone.name }}
          </option>
        </select>
      </div>
      <div class="promo-error" v-if="!(this.errors.time_zone_code.length === 0)">
        {{ errors.time_zone_code ? errors.time_zone_code[0] : "" }}
      </div>
    </div>

    <div class="row">
      <div class="form-step">
        <label for="node-8" class="label-form">
          {{ labels.sponsor_city }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.nickname_tooltip_1 }} {{ tooltips.nickname_tooltip_2 }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.city.length === 0) }"
          type="text"
          maxlength="256"
          v-model="city"
          name="city"
          :placeholder="labels.placeholder_name_or_firm"
          id="node-8"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.city.length === 0)">
        {{ errors.city ? errors.city[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-13" class="label-form">
          {{ labels.sponsor_city_code }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.oferta_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger': !(this.errors.city_code.length === 0),
          }"
          type="string"
          maxlength="256"
          v-model="city_code"
          name="city_code"
          :placeholder="labels.placeholder_email"
          id="node-13"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.city_code.length === 0)">
        {{ errors.city_code ? errors.city_code[0] : "" }}
      </div>
    </div>

    <div class="form-step">
      <label for="node-8" class="label-form">
        {{ labels.address }}
        <span class="info"> ? </span>
        <span class="tooltiptext">
          {{ tooltips.oferta_tooltip }}
        </span>
      </label>
      <input
        v-bind:class="{ 'border-danger': !(this.errors.address.length === 0) }"
        type="text"
        maxlength="256"
        v-model="address"
        name="address"
        :placeholder="labels.placeholder_name_or_firm"
        id="node-8"
        required
      />
    </div>
    <div class="promo-error" v-if="!(this.errors.address.length === 0)">
      {{ errors.address ? errors.address[0] : "" }}
    </div>

    <div class="row" v-if="isFirmType">
      <div class="form-step">
        <label for="node-8" class="label-form">
          {{ labels.firm_name }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.oferta_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.firm.length === 0) }"
          type="text"
          maxlength="256"
          v-model="firm"
          name="firm"
          :placeholder="labels.placeholder_name_or_firm"
          id="node-8"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.firm.length === 0)">
        {{ errors.name ? errors.firm[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-13" class="label-form">
          {{ labels.firm_inn }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.firm_inn_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.inn.length === 0) }"
          type="string"
          maxlength="256"
          v-model="inn"
          name="inn"
          :placeholder="labels.placeholder_email"
          id="node-13"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.inn.length === 0)">
        {{ errors.inn ? errors.inn[0] : "" }}
      </div>
    </div>

    <div class="row" v-if="!isFirmType">
      <div class="form-step">
        <label for="node-8" class="label-form">
          {{ labels.sponsor_name }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.oferta_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.name.length === 0) }"
          type="text"
          maxlength="256"
          v-model="name"
          name="name"
          :placeholder="labels.placeholder_name_or_firm"
          id="node-8"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.name.length === 0)">
        {{ errors.name ? errors.name[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-13" class="label-form">
          {{ labels.sponsor_surname }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.oferta_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger': !(this.errors.surname.length === 0),
          }"
          type="text"
          maxlength="256"
          v-model="surname"
          name="surname"
          :placeholder="labels.placeholder_email"
          id="node-13"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.surname.length === 0)">
        {{ errors.surname ? errors.surname[0] : "" }}
      </div>
    </div>

    <div class="row">
      <div class="form-step">
        <label for="node-8" class="label-form">
          {{ labels.sponsor_nickname }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.nickname_tooltip_1 }} {{ tooltips.nickname_tooltip_2 }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.name.length === 0) }"
          type="text"
          maxlength="256"
          v-model="planner_name"
          name="planner_name"
          :placeholder="labels.placeholder_name_or_firm"
          id="node-8"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.planner_name.length === 0)">
        {{ errors.planner_name ? errors.planner_name[0] : "" }}
      </div>

      <div class="form-step">
        <label for="node-13" class="label-form">
          {{ labels.sponsor_mail }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.mail_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{ 'border-danger': !(this.errors.email.length === 0) }"
          type="email"
          maxlength="256"
          v-model="email"
          name="email"
          :placeholder="labels.placeholder_email"
          id="node-13"
          required
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.email.length === 0)">
        {{ errors.email ? errors.email[0] : "" }}
      </div>
    </div>

    <div class="form-step" v-if="isPublicType">
      <label for="node-8" class="label-form">
        {{ labels.qr_page }}
        <span class="info"> ? </span>
        <span class="tooltiptext">
          {{ tooltips.page_tooltip }}
        </span>
      </label>
      <input
        v-bind:class="{ 'border-danger': !(this.errors.page.length === 0) }"
        type="text"
        maxlength="256"
        v-model="page"
        name="page"
        :placeholder="labels.placeholder_name_or_firm"
        id="node-8"
        required
      />
    </div>
    <div class="promo-error" v-if="!(this.errors.page.length === 0)">
      {{ errors.page ? errors.page[0] : "" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Object,
      default: {},
    },
    errors: {
      type: Object,
      default: {},
    },
    old: {
      type: Object,
      default: {},
    },
    time_zones: {
      type: Array,
      default: [],
    },
    countries: {
      type: Array,
      default: [],
    },
    planner_types: {
      type: Array,
      default: [],
    },
    event_types: {
      type: Array,
      default: [],
    },
    public: {
      type: Number,
      default: 1,
    },
    firmtype: {
      type: Number,
      default: 2,
    },
    tooltips: {
      type: Object,
      default: {},
    },
  },
  created() {
    this.setDefaultValues();
  },
  data() {
    return {
      selected_event_type: this.old.event_type_id,
      selected_planner_type: this.old.type_id,
      selected_country_id: this.old.country_id,
      selected_time_zone_code: this.old.time_zone_code,
      display: true,
      name: this.old.name,
      surname: this.old.surname,
      planner_name: this.old.planner_name,
      city: this.old.city,
      city_code: this.old.city_code,
      firm: this.old.firm,
      inn: this.old.inn,
      address: this.old.address,
      email: this.old.email,
      page: this.old.page,
    };
  },
  computed: {
    isFirmType() {
      return this.selected_planner_type == this.firmtype;
    },
    isPublicType() {
      return this.selected_event_type == this.public;
    },
  },
  methods: {
    setDefaultValues() {
      this.selected_event_type = this.old.event_type_id ?? 1;
      this.selected_planner_type = this.old.type_id ?? 1;
      this.selected_country_id = this.old.country_id ?? 177;
      this.selected_time_zone_code = this.old.time_zone_code ?? 4;
    },
  },
};
</script>
