<template>
    <div>
        <div class="mb-3">
            <label for="scans">Сканирования:</label>
            <button @click="createWinning" class="btn btn-success float-right">Добавить</button>
        </div>
        <table id="scans" class="table">
            <thead>
                <tr>
                    <td class="font-weight-bold">Выигрышное сканирование</td>
                    <td class="font-weight-bold">Сумма выигрыша</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
            <template v-for="result in results">
                <tr>
                    <td>
                        <label>
                            <input class="form-control" type="number" min="1"
                                   v-model="result['position']"
                                   :name="'winnings[' + result.index + '][position]'"
                            >
                        </label>
                    </td>
                    <td>
                        <label>
                            <input class="form-control" type="number" min="0.00001" step="0.00001"
                                   v-model="result['amount']"
                                   :name="'winnings[' + result.index + '][amount]'"
                            >
                        </label>
                    </td>
                    <td>
                        <button class="btn btn-danger float-right" @click="deleteWinning(result.index, $event)">
                            Удалить
                        </button>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        winnings: {
            type: Array,
            default: [],
        }
    },
    created() {
        this.prepareWinnings()
    },
    data() {
        return {
            results: [],
        }
    },
    methods: {
        prepareWinnings() {
            this.winnings.forEach((winning, index) =>
                winning.index = index
            )

            this.results = this.winnings
        },
        createWinning(event) {
            event.preventDefault();
            this.results.push({index: this.results.length + 1})
        },
        deleteWinning(index, event) {
            event.preventDefault();
            this.results = this.results.filter((obj) =>
                obj.index !== index
            )
        }
    }
}
</script>
