require('./bootstrap');

import Vue from 'vue';

Vue.component(
    'general-data',
    require('./components/GeneralData.vue').default,
)

Vue.component(
    'basic-bar',
    require('./components/BasicBar.vue').default,
)

Vue.component(
    'column-charts',
    require('./components/ColumnCharts.vue').default,
)

Vue.component(
    'apex-chart',
    require('./components/ApexCharts.vue').default,
)

Vue.component(
    'stacked-bar',
    require('./components/StackedBar.vue').default,
)

Vue.component(
    'basic',
    require('./components/Basic.vue').default,
)

Vue.component(
    'winnings',
    require('./components/Winnings.vue').default,
)

Vue.component(
    'winnings-front',
    require('./components/Winnings-front.vue').default,
)

Vue.component(
    'syncing-charts',
    require('./components/SyncingCharts.vue').default,
)

Vue.component(
    'custom-data-labels',
    require('./components/CustomDataLabels.vue').default,
)

Vue.component(
    'admin-access',
    require('./components/AdminAccess.vue').default,
)

const app = new Vue({
    el: '#app'
});
