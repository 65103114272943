<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: [],
        },
        title: {
            type: String,
            default: 'Name',
        }
    },
    created() {
        let seriesData = [];
        let id = [];
        let quantity = {};
        const data = {}
        quantity.name = 'count';
        quantity.data = [];

        for (let val in this.values) {
            data[val] = this.values[val];
        }

        const valuesArr = Array.from(Object.values(data));
        const sortedValues = valuesArr.sort((a, b) => b - a);
        const newMap = new Map();

        sortedValues.forEach((item, index) => {
            const targetKey = Object.keys(data).find((key) => data[key] === item);
            newMap.set(targetKey, item);
        });

        newMap.forEach((item, index) => {
            id.push(index);
            quantity.data.push(item);
        });

        seriesData.push(quantity);

        this.series = seriesData;
        this.chartOptions.xaxis.categories = id;

    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: this.title,
                    align: 'left'
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [],
                },
            },
        }
    },
}
</script>
