<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: {},
        },
        title: {
            type: String,
            default: 'Name',
        }
    },
    created() {
        let seriesData = [];
        let countries = [];

        for (let val in this.values) {
            countries.push(val);

            for (let index in this.values[val]){
                let language = {};
                language.name = index;
                language.data = this.values[val][index];

                seriesData.push(language);
            }
        }

        seriesData.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

        let result = Object.values(seriesData.reduce((a, {name, data}) => {
            (a[name] || (a[name] = {name, data: []})).data.push(data);
            return a;
        }, {}));

        this.series = result;
        this.chartOptions.xaxis.categories = countries;

    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: this.title,
                    align: 'left'
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.6,
                        opacityTo: 0.8,
                    }
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
            },
        }
    },
}
</script>
