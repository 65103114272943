<template>
  <div class="line">
    <div class="row">
      <div class="form-step">
        <label for="date" class="label-form">
          {{ labels.event_date }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.date_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger':
              !(this.errors.date.length === 0) ||
              !(this.errors.activate_date.length === 0),
          }"
          type="date"
          class="input data w-input"
          maxlength="256"
          v-model="date"
          :min="this.date_min"
          @change="dateUpdate"
          name="date"
          id="date"
          required=""
        />
      </div>
      <div class="form-step">
        <label for="time" class="label-form">
          {{ labels.time_of_start }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.date_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger':
              !(this.errors.time.length === 0) ||
              !(this.errors.activate_date.length === 0),
          }"
          type="time"
          class="input clock w-input"
          maxlength="256"
          v-model="time"
          @change="timeUpdate"
          :disabled="date == null"
          name="time"
          placeholder="HH:MM"
          id="node-24"
          required=""
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.date.length === 0)">
        {{ errors.date ? errors.date[0] : "" }}
      </div>
      <div class="promo-error" v-if="!(this.errors.time.length === 0)">
        {{ errors.time ? errors.time[0] : "" }}
      </div>
      <div class="promo-error" v-if="!(this.errors.activate_date.length === 0)">
        {{ errors.activate_date ? errors.activate_date[0] : "" }}
      </div>
    </div>

    <div class="row">
      <div class="form-step">
        <label for="end_date" class="label-form">
          {{ labels.date_of_end }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.date_end_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger':
              !(this.errors.end_date.length === 0) ||
              !(this.errors.finish_date.length === 0),
          }"
          type="date"
          class="input data w-input"
          maxlength="256"
          v-model="end_date"
          :min="date"
          :max="end_date_max"
          @change="timeUpdate"
          :disabled="date == null"
          name="end_date"
          id="end_date"
          required=""
        />
      </div>
      <div class="form-step">
        <label for="date" class="label-form">
          {{ labels.time_of_end }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.end_tooltip }}
          </span>
        </label>
        <input
          v-bind:class="{
            'border-danger':
              !(this.errors.end_time.length === 0) ||
              !(this.errors.finish_date.length === 0),
          }"
          type="time"
          class="input clock w-input"
          maxlength="256"
          v-model="end_time"
          :min="end_time_min"
          :max="end_time_max"
          @change="timeUpdate"
          :disabled="end_date == null"
          name="end_time"
          placeholder="HH:MM"
          id="node-24"
          required=""
        />
      </div>
      <div class="promo-error" v-if="!(this.errors.end_date.length === 0)">
        {{ errors.end_date ? errors.end_date[0] : "" }}
      </div>
      <div class="promo-error" v-if="!(this.errors.end_time.length === 0)">
        {{ errors.end_time ? errors.end_time[0] : "" }}
      </div>
      <div class="promo-error" v-if="!(this.errors.finish_date.length === 0)">
        {{ errors.finish_date ? errors.finish_date[0] : "" }}
      </div>
    </div>

    <div class="row">
      <div class="form-step">
        <label for="node-19" class="label-form">
          {{ labels.scan_max }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.max_tooltip }}
          </span>
        </label>
        <div class="data-box">
          <input
            v-bind:class="{
              'border-danger': !(this.errors.member_scans_count.length === 0),
            }"
            type="number"
            min="1"
            max="999998"
            @change="defineVisibility"
            v-model="scans_count"
            :disabled="isDisabled"
            class="input scan w-input"
            maxlength="256"
            name="member_scans_count"
            :placeholder="labels.placeholder_scan_num"
            id="node-19"
            required
          />
          <label class="w-checkbox">
            <input
              type="checkbox"
              id="checkbox-2"
              v-model="checked"
              @change="blockCounter"
              name="member_scans_count_infinite"
              style="opacity: 0; position: absolute; z-index: -1"
            />
            <span
              class="
                w-checkbox-input w-checkbox-input--inputType-custom
                check
                _2
              "
            ></span>
            <span class="checkbox-label w-form-label">{{ labels.or_inf }}</span>
          </label>
        </div>
      </div>

      <div class="form-step" v-if="display === true && display !== 'none'">
        <label for="node-9" class="label-form">
          {{ labels.scan_frequency }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.frequency_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.scans_rate_id.length === 0),
          }"
          id="node-9"
          required=""
          v-model="selected_rate"
          name="scans_rate_id"
          class="select _2 w-select"
        >
          <option
            v-for="scan_rate in scans_rates"
            :value="scan_rate.id"
            :key="scan_rate.id"
          >
            {{ scan_rate.name_localized }}
          </option>
        </select>
      </div>
      <div
        class="promo-error"
        v-if="!(this.errors.member_scans_count.length === 0)"
      >
        {{ errors.member_scans_count ? errors.member_scans_count[0] : "" }}
      </div>
      <div class="promo-error" v-if="!(this.errors.scans_rate_id.length === 0)">
        {{ errors.scans_rate_id ? errors.scans_rate_id[0] : "" }}
      </div>
    </div>
    <div class="row">
      <div class="form-step">
        <label for="node-26" class="label-form">
          {{ labels.promocode }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.promocode_tooltip }}
          </span>
        </label>
        <input
          type="text"
          class="input promo w-input"
          v-bind:class="{
            'border-success': isSuccess && !isError,
            'border-danger':
              (isError || !(errors.code.length === 0)) && !isSuccess,
          }"
          v-model="promocode"
          style="width: 66%"
          @change="setCode"
          maxlength="256"
          name="code"
          :placeholder="labels.placeholder_promo_code"
          id="node-26"
        />
        <button
          @click="usePromocode"
          class="w-button p-1"
          style="width: 32%; height: 50px; border-radius: 7px"
        >
          {{ labels.promocode_use }}
        </button>
      </div>
      <div class="text-success text-right pr-3" v-if="isSuccess && !isError">
        {{ labels.promocode_success }}
      </div>
      <div
        class="promo-error text-right pr-3"
        v-if="(isError || !(errors.code.length === 0)) && !isSuccess"
      >
        {{ labels.promocode_failure }}
      </div>
    </div>
    <div class="row">
      <div class="form-step">
        <label for="node-10" class="label-form">
          {{ labels.prize_amount }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.amount_tooltip }}
          </span>
        </label>
        <select
          id="node-10"
          v-model="selected"
          required=""
          @change="createWinnings($event)"
          data-name="winners_count"
          class="select wins w-select"
        >
          <option v-for="id in ids" :value="id" :key="id">{{ id }}</option>
        </select>
      </div>
      <div class="form-step">
        <label for="node-10" class="label-form">
          {{ labels.prize_currency }}
          <span class="info"> ? </span>
          <span class="tooltiptext">
            {{ tooltips.currency_tooltip }}
          </span>
        </label>
        <select
          v-bind:class="{
            'border-danger': !(this.errors.currency_id.length === 0),
          }"
          required=""
          @change="setCurrency($event)"
          v-model="old.currency_id"
          name="currency_id"
          class="select money w-select"
        >
          <option
            v-for="currency in currencies"
            :value="currency.id"
            :key="currency.id"
          >
            {{ currency.symbol }}
          </option>
        </select>
      </div>
      <div class="promo-error" v-if="!(this.errors.currency_id.length === 0)">
        {{ errors.currency_id ? errors.currency_id[0] : "" }}
      </div>
    </div>
    <div class="row">
      <template v-for="result in results">
        <div id="scans" class="form-step" :key="result.index">
          <label class="label-form">
            {{ labels.scan_win }} {{ result.index }}{{ labels.prize }}
            <span class="info"> ? </span>
            <span class="tooltiptext">
              {{ tooltips.win_tooltip }}
            </span>
          </label>
          <input
            type="number"
            class="input win w-input"
            min="1"
            v-model="result['position']"
            :name="'winnings[' + result.index + '][position]'"
            :placeholder="labels.placeholder_num"
            id="node-20"
            required
          />
        </div>
        <div id="scans" class="form-step" :key="result.index">
          <label class="label-form">
            {{ labels.win_sum }} {{ result.index }}{{ labels.prize }}
            <span class="info"> ? </span>
            <span class="tooltiptext">
              {{ tooltips.sum_tooltip }}
            </span>
          </label>
          <div class="data-box inp_100 inp_summa">
            <input
              type="number"
              @change="countBudget()"
              class="input procent w-input"
              :min="min"
              :step="min"
              v-model="result['amount']"
              :name="'winnings[' + result.index + '][amount]'"
              :placeholder="labels.placeholder_sum"
              id="node-21"
              required
            />
            <div class="summa currency">{{ currency }}</div>
          </div>
        </div>
      </template>
      <div v-if="!(this.errors.winnings === null)" class="promo-error">
        {{ this.errors.winnings ? this.errors.winnings[0] : "" }}
      </div>
    </div>
    <div class="form-step form-step-budget">
      <label class="label-form">
        {{ labels.budget }}
        <span class="info"> ? </span>
        <span class="tooltiptext">
          {{ tooltips.budget_tooltip }}
        </span>
      </label>
      <div class="budget">
        <div class="price" v-bind:style="{ opacity: opacity }">
          {{ budget }}
        </div>
        <div class="currency" v-bind:style="{ opacity: opacity }">
          {{ currency }}
        </div>
      </div>
    </div>
    <div class="form-step form-step-budget">
      <label class="label-form">
        {{ labels.result_sum }}
        <span class="info"> ? </span>
        <span class="tooltiptext">
          {{ tooltips.result_tooltip }}
        </span>
      </label>
      <div class="budget">
        <div class="price" v-bind:style="{ opacity: opacity }">{{ total }}</div>
        <div class="currency" v-bind:style="{ opacity: opacity }">
          {{ currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currencies: {
      type: Array,
      default: [],
    },
    labels: {
      type: Object,
      default: {},
    },
    tooltips: {
      type: Object,
      default: {},
    },
    commission: {
      type: Number,
      default: 7,
    },
    status_disabled: {
      type: Number,
      default: 2,
    },
    winners_count: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Object,
      default: {},
    },
    old: {
      type: Object,
      default: {},
    },
    route: {
      type: String,
      default: "",
    },
    default_rate: {
      type: Number,
      default: 1,
    },
    date_min: {
      type: String,
      default: "",
    },
    days_after_max: {
      type: Number,
      default: "",
    },
    scans_rates: {
      type: Array,
      default: [],
    },
  },
  created() {
    this.selected_rate = this.old.scans_rate_id ?? this.default_rate;
    this.scans_count = this.old.scans_count;
    this.prepareOld();
    for (let i = 1; i <= this.winners_count; i++) {
      this.ids.push(i);
    }
  },
  data() {
    return {
      results: [],
      ids: [],
      selected:
        Object.keys(this.old.winnings).length == 0
          ? 1
          : Object.keys(this.old.winnings).length,
      budget: 0,
      total: 0,
      opacity: 0,
      currency: this.currencies[0]["symbol"],
      promocode: null,
      isSuccess: false,
      discount: 0,
      isError: false,
      minBudget: 10000,
      min: 1,
      round_to: 0.01,
      is_enough_budget: false,
      display: false,
      checked: this.old.member_scans_count_infinite === true,
      isDisabled: this.old.member_scans_count_infinite === true,
      selected_rate: this.old.scans_rate_id,
      scans_count: this.old.scans_count,
      date: this.old.date,
      time: this.old.time,
      end_date_max: this.old.date,
      end_date: this.old.end_date,
      end_time: this.old.end_time,
      end_time_min: "",
      end_time_max: "",
    };
  },
  methods: {
    prepareOld() {
      this.prepareWinnings();
      this.prepareCode();
      this.prepareCurrency();
      this.countBudget();
      this.defineVisibility();
      this.dateUpdate();
    },
    prepareCurrency() {
      this.old.currency_id = this.old.currency_id ?? this.currencies[0]["id"];
      this.currency = this.currencies[this.old.currency_id - 1]["symbol"];
      this.setMinBudget(this.old.currency_id);
    },
    prepareWinnings() {
      if (!(Object.keys(this.old.winnings).length == 0)) {
        Object.values(this.old.winnings).forEach(
          (winning, index) => (winning.index = index + 1)
        );

        this.results = Object.keys(this.old.winnings).map((key) => {
          return this.old.winnings[key];
        });
      } else {
        this.results.push({ index: this.results.length + 1 });
      }
    },
    prepareCode() {
      if (this.old.code) {
        this.promocode = this.old.code;
      } else {
        this.promocode = null;
      }
    },
    createWinnings(event) {
      let length = this.results.length ?? Object.keys(this.results).length;
      if (event.target.value < length) {
        this.results = this.results.filter(
          (obj) => obj.index < Number(event.target.value) + 1
        );
      } else if (event.target.value > length) {
        let diff = event.target.value - length;
        let i;
        for (i = 0; i < diff; i++) {
          this.results.push({ index: this.results.length + 1 });
        }
      }
      this.countBudget();
    },
    countBudget() {
      this.budget = 0;
      Object.entries(this.results).forEach(([key, val]) => {
        this.budget += Number(val.amount);
        this.budget =
          Math.round((this.budget + Number.EPSILON) * (1 / this.round_to)) /
          (1 / this.round_to);
      });
      this.total =
        this.budget + (this.budget * (this.commission - this.discount)) / 100;
      this.total =
        Math.round((this.total + Number.EPSILON) * (1 / this.round_to)) /
        (1 / this.round_to);

      if (isNaN(this.budget)) {
        this.opacity = 0;
      } else {
        this.opacity = 1;
        this.isEnoughBudget();
      }
    },
    setCode(event) {
      this.promocode = event.target.value;
    },
    setCurrency(event) {
      this.currency = this.currencies[event.target.value - 1]["symbol"];
      this.setMinBudget(event.target.value);
    },
    usePromocode(event) {
      event.preventDefault();
      axios
        .get(this.route + "/" + this.promocode)
        .then((response) => {
          if (response.data.original.status_id == this.status_disabled) {
            this.isError = true;
            this.isSuccess = false;
            this.discount = 0;
            this.countBudget();
          } else {
            this.discount = response.data.original.discount;
            this.isSuccess = true;
            this.isError = false;
            this.countBudget();
          }
        })
        .catch((error) => {
          this.isError = true;
          this.isSuccess = false;
          this.discount = 0;
          this.countBudget();
        });
    },
    setMinBudget(currencyId) {
      this.minBudget = this.currencies[currencyId - 1].minimal_budget;

      if (this.currencies[currencyId - 1].code == "BTC") {
        this.min = 0.00001;
        this.round_to = 0.00000001;
      } else {
        this.min = 1;
        this.round_to = 0.01;
      }
      this.isEnoughBudget();
    },
    isEnoughBudget() {
      this.is_enough_budget = this.budget >= this.minBudget;
    },
    blockCounter() {
      if (this.checked) {
        this.isDisabled = true;
        this.display = true;
      } else {
        this.isDisabled = false;
        this.defineVisibility();
      }
    },
    defineVisibility() {
      if (this.scans_count > 1) {
        this.display = true;
      } else {
        this.display = false;
      }
    },
    dateUpdate() {
      const start_date = new Date(this.date);
      const end_date_max = new Date(this.date);
      const end_date = new Date(this.end_date);

      end_date_max.setDate(end_date_max.getDate() + this.days_after_max);
      let month = (end_date_max.getMonth() + 1).toString();
      let day = end_date_max.getDate().toString();
      let year = end_date_max.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      this.end_date_max = [year, month, day].join("-");

      if (this.end_date == null || start_date > end_date) {
        this.end_date = this.date;
      } else if (end_date > end_date_max) {
        this.end_date = this.end_date_max;
      }

      this.timeUpdate();
    },
    timeUpdate() {
      const start_datetime = new Date(this.date + ' ' + this.time);
      let end_datetime;
      if (this.end_time != null) {
        end_datetime = new Date(this.end_date + ' ' + this.end_time);
      } else {
        end_datetime = new Date(this.end_date);
      }

      const end_datetime_max = new Date(start_datetime);
      end_datetime_max.setDate(end_datetime_max.getDate() + this.days_after_max);

      if (
        start_datetime >= end_datetime &&
        start_datetime.getDate() == end_datetime.getDate()
      ) {
        const min_time = new Date(start_datetime);
        min_time.setTime(min_time.getTime() + 1 * 60000);
        this.end_time = min_time.getHours() + ':' + min_time.getMinutes();
        this.end_time_min = min_time.getHours() + ':' + min_time.getMinutes();
        this.end_time_max = '';
      } else if (end_datetime > end_datetime_max) {
        this.end_time = this.time;
        this.end_time_min = '';
        this.end_time_max = this.time;
      } else {
        this.end_time_min = '';
        this.end_time_max = '';
      }
    },
  },
};
</script>
