<template>
    <div id="wrapper">
        <div id="chart-line1">
            <apexchart type="line" height="160" :options="chartOptions1" :series="seriesLine1"></apexchart>
        </div>
        <div id="chart-line2">
            <apexchart type="line" height="160" :options="chartOptions2" :series="seriesLine2"></apexchart>
        </div>
        <div id="chart-line3">
            <apexchart type="line" height="160" :options="chartOptions3" :series="seriesLine3"></apexchart>
        </div>
        <div id="chart-area">
            <apexchart type="area" height="160" :options="chartOptions4" :series="seriesLine4"></apexchart>
        </div>
    </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: [],
        },
        title: {
            type: String,
            default: 'Name',
        }
    },
    created() {
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let data4 = [];

        for (let val in this.values['Евро']){
            data1.push([ Number(val), this.values['Евро'][val] ]);
        }

        for (let val in this.values['Рубли']){
            data2.push([ Number(val), this.values['Рубли'][val] ]);
        }

        for (let val in this.values['Доллары']){
            data3.push([ Number(val), this.values['Доллары'][val] ]);

        }

        for (let val in this.values['Биткойны']){
            data4.push([ Number(val), this.values['Биткойны'][val] ]);
        }
        let today = new Date();


        let dataSeries1 = this.values['Евро'] ? data1 : [[today.getTime(),0]];
        let dataSeries2 = this.values['Рубли'] ? data2 : [[today.getTime(),0]];
        let dataSeries3 = this.values['Доллары'] ? data3 : [[today.getTime(),0]];
        let dataSeries4 = this.values['Биткойны'] ? data4 : [[today.getTime(),0]];

        this.seriesLine1.push({data: dataSeries1, name:'Евро'});
        this.seriesLine2.push({data: dataSeries2, name:'Рубли'});
        this.seriesLine3.push({data: dataSeries3, name:'Доллары'});
        this.seriesLine4.push({data: dataSeries4, name:'Биткойны'});

    },
    data() {
        return {
            seriesLine1: [],
            chartOptions1: {
                chart: {
                    id: 'fb',
                    group: 'social',
                    type: 'line',
                    height: 160
                },
                colors: ['#008FFB'],
                yaxis: {
                    labels: {
                        minWidth: 40
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
                title: {
                    text: this.title,
                    align: 'left'
                },
            },

            seriesLine2: [],
            chartOptions2: {
                chart: {
                    id: 'tw',
                    group: 'social',
                    type: 'line',
                    height: 160
                },
                colors: ['#546E7A'],
                yaxis: {
                    labels: {
                        minWidth: 40
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
            },

            seriesLine3: [],
            chartOptions3: {
                chart: {
                    id: 'yt',
                    group: 'social',
                    type: 'area',
                    height: 160
                },
                colors: ['#00E396'],
                yaxis: {
                    labels: {
                        minWidth: 40
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
            },
            seriesLine4: [],
            chartOptions4: {
                chart: {
                    id: 'ytw',
                    group: 'social',
                    type: 'area',
                    height: 160
                },
                colors: ['#00E396'],
                yaxis: {
                    labels: {
                        minWidth: 40
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
            },
        }
    },
}
</script>
