<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: [],
        },
        title: {
            type: String,
            default: 'Name',
        }
    },
    created() {
        let seriesData = [];

        for (let val in this.values) {
            let temp = {};
            temp.data = [];
            temp.name = val;

           this.chartOptions.xaxis.categories.push(val);
           temp.data.push(this.values[val]);
           seriesData.push(temp);
        }

        this.series = seriesData;
    },
    data() {
        return {
            series: [{
                data: [],
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                }
            },
        }
    }
}
</script>
