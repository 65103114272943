<template>
<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: [],
        },
        title: {
            type: String,
            default: 'Name',
        },
    },
    created() {
        let seriesData = [];
        let dataIndex = 0;

        let obj = []

        for (let [key, country] of Object.entries(this.values)) {
            if (!this.chartOptions.xaxis.categories.includes(key)) {
                this.chartOptions.xaxis.categories.push(key);
            }

            for (let currency of Object.entries(country)) {
                obj.hasOwnProperty(currency[0]) ? obj[currency[0]].push(currency[1]) : obj[currency[0]] = [currency[1]];
            }
        }

        for (const currency in obj) {
            let temp = {};

            temp.name = currency;
            temp.data = obj[currency];

            seriesData.push(temp);
        }
        this.series = seriesData;
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: this.title
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            },
        }
    }
}
</script>
