<template>
  <div class="form-group">
    <table class="table">
      <thead>
        <th>Роли</th>
        <th
          v-for="(name, option) in rolesOptions"
          :key="option"
          :value="option"
        >
          {{ name }}
        </th>
      </thead>
      <tbody>
        <tr v-for="role in roles" :key="role.code" :value="role.code">
          <td>{{ role.name }}</td>
          <td
            v-for="(name, option) in rolesOptions"
            :key="option"
            :value="option"
          >
            <div
              v-if="role.options.includes(option)"
              class="custom-control custom-switch"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="'role_codes[' + role.code + '][' + option + ']'"
                :name="'role_codes[' + role.code + '][]'"
                :value="option"
                v-model="summary[role.code]"
                @change="change(role.code, option)"
              />
              <label
                class="custom-control-label"
                :for="'role_codes[' + role.code + '][' + option + ']'"
              ></label>
            </div>
            <div
              v-else-if="option == 'manage'"
              class="custom-control custom-switch"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="'role_codes[' + role.code + '][' + option + ']'"
                :name="'role_codes[' + role.code + '][]'"
                :value="option"
                v-model="summary[role.code]"
                @change="change(role.code, option)"
              />
              <label
                class="custom-control-label"
                :for="'role_codes[' + role.code + '][' + option + ']'"
              ></label>
            </div>
            <div v-else>-</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    roles: {
      type: Object,
      default: {},
    },
    rolesOptions: {
      type: Object,
      default: {},
    },
    adminRoles: {
      type: Object,
      default: {},
    },
  },
  created() {
    this.setValues();
  },
  data() {
    return {
      summary: this.adminRoles,
    };
  },
  computed: {},
  methods: {
    change(role, option) {
      if (option == "view" && !this.summary[role].includes(option)) {
        this.summary[role] = [];
      } else if (option == "manage" && this.summary[role].includes(option)) {
        this.summary[role] = this.roles
          .find((x) => x.code == role)
          .options.concat("manage");
      } else if (
        option != "view" &&
        this.summary[role].includes(option) &&
        !this.summary[role].includes("view")
      ) {
        this.summary[role].push("view");
      } else if (
        option != "manage" &&
        !this.summary[role].includes(option) &&
        this.summary[role].includes("manage")
      ) {
        this.summary[role].splice(this.summary[role].indexOf("manage"));
      }
    },
    setValues() {
      for (const [role, options] of Object.entries(this.summary)) {
        if (options.includes("manage")) {
          this.summary[role] = this.roles
            .find((x) => x.code == role)
            .options.concat("manage");
        }
      }
    },
  },
};
</script>
