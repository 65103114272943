<template>
    <apexchart type="area" height="300" stacked="true" :options="chartOptions" :series="series"></apexchart>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Object,
            default: [],
        },
        title: {
            type: String,
            default: 'Name',
        }
    },
    created() {
        let seriesData = [];
        for (let val in this.values) {
            let temp = {};

            temp.name = val;
            temp.data = [];

            for (let date in this.values[val]) {
                temp.data.push([(new Date(date)).getTime(), this.values[val][date]])
            }

            seriesData.push(temp);
        }

        this.series = seriesData;
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'area',
                    stacked: false,
                    height: 350,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        autoSelected: 'zoom'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: this.title,
                    align: 'left'
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.6,
                        opacityTo: 0.8,
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
            },
        }
    },
}
</script>
